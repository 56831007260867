.bg-gradient-teal-green {
    background: linear-gradient(to right, #7dd8d4, #407a58);
  }
  
  .bg-gradient-blue {
    background: linear-gradient(to right, #7dc3fd, #2b5c89);
  }
  
  .bg-gradient-red {
    background: linear-gradient(to right, #f69898, #be4141);
  }
  
  .bg-gradient-yellow {
    background: linear-gradient(to right, #ffe68d, #ba903c);
  }
  .bg-gradient-purple {
    background: linear-gradient(to right, #bf9bff, #6f42c1);
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    background: #f2f2f2;
  }
  
  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 10px;
    white-space: nowrap;
    position: relative;

  }
  
  