.pdf-container {
  overflow: auto;
  max-height: 70vh;
}

.pdf-container canvas {
  width: 100% !important;
  height: auto !important;
}

.react-pdf__Page__canvas {
  display: block;
  margin: 0 auto;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}